<template>
  <div>
    <v-card>
      <div class="text-center mx-auto ma-6 pa-6">
        <h3>Leaderboards</h3>
        <p>Improved Weekly & Monthly leaderboards coming soon.</p>
        <v-btn
          color="primary"
          to="/dashboard"
          class="mb-4 mt-4"
          outlined
        >
          Back to home
        </v-btn>
      </div>
    </v-card>
    <div class="d-none">
      We are working on a new leaderboard, leaderboards will be generated for:
      <ul>
        <li>Fantaty Fight Rournaments Rank</li>
        <li>Arcade rankings</li>
        <li>Per Country Rankings</li>
        <li>Fight Simulation Rankings</li>
        <li>Career Mode Tank</li>
        <li>Most Valuable Team Rankings</li>
        <li>Private & Public League Rankings</li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mdiAlert} from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiAlert,
      },
    }
  },
}
</script>

<style lang="scss">
</style>
